// Generated by Framer (1b127cb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["OZQn6KhDD", "gUI531rnj"];

const variantClassNames = {gUI531rnj: "framer-v-1cw3bcz", OZQn6KhDD: "framer-v-kl0rbm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Hiden States": "OZQn6KhDD", "Show States": "gUI531rnj"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "OZQn6KhDD", title: FtCUenw_j = "View Locations", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "OZQn6KhDD", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap18ukgoq = activeVariantCallback(async (...args) => {
setVariant("gUI531rnj")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-y9OHB", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-kl0rbm", className)} data-framer-name={"Hiden States"} data-highlight layoutDependency={layoutDependency} layoutId={"OZQn6KhDD"} onTap={onTap18ukgoq} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} transition={transition} {...addPropertyOverrides({gUI531rnj: {"data-framer-name": "Show States"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>View Locations</motion.p></React.Fragment>} className={"framer-1de2x2m"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"NRE1uXflt"} style={{"--extracted-r6o4lv": "rgb(51, 51, 51)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={FtCUenw_j} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-y9OHB [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-y9OHB .framer-10zsnzq { display: block; }", ".framer-y9OHB .framer-kl0rbm { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: visible; padding: 15px 15px 15px 15px; position: relative; width: min-content; }", ".framer-y9OHB .framer-1de2x2m { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-y9OHB .framer-kl0rbm { gap: 0px; } .framer-y9OHB .framer-kl0rbm > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-y9OHB .framer-kl0rbm > :first-child { margin-left: 0px; } .framer-y9OHB .framer-kl0rbm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 135
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"gUI531rnj":{"layout":["auto","fixed"]}}}
 * @framerVariables {"FtCUenw_j":"title"}
 */
const FramerZAXFlojVH: React.ComponentType<Props> = withCSS(Component, css, "framer-y9OHB") as typeof Component;
export default FramerZAXFlojVH;

FramerZAXFlojVH.displayName = "State Show";

FramerZAXFlojVH.defaultProps = {height: 40, width: 135};

addPropertyControls(FramerZAXFlojVH, {variant: {options: ["OZQn6KhDD", "gUI531rnj"], optionTitles: ["Hiden States", "Show States"], title: "Variant", type: ControlType.Enum}, FtCUenw_j: {defaultValue: "View Locations", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerZAXFlojVH, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/ZAXFlojVH:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf", weight: "700"}])